$color-bright-blue: #00ADFF; // SkyKick Blue
$color-deep-blue: #1C8DE3;
$color-cool-gray: #dfe4ec;
$color-gray: #9ba3ae;
$color-blue-gray: #4f5869;
$color-navy-blue: #30394f;
$color-red: #FF5252;
$color-amber: #FFC107;
$color-emerald: #1ED093;
$color-aqua: #4AD0E2;
$color-sapphire: #628EE9;
$color-amethyst: #9772d9;
$color-teal: #15818C;
$color-white: #FFFFFF;
$color-black: #000000;
$color-off-white: #f5f5f5;

// icon colors from prod website... we should really pull in core css
$icon-success: #0eb43a;
$icon-warning: #f08800;
$icon-muted: #a0abb6;

$action-selection: rgba(168, 215, 255, 0.5);
$search-result: #d0eaff80;
$search-result-hover: #c4dcf080;
$content-top-margin: 10px;

$border-color: #d9d9d9;

:root {
    --default-text-color: #000000; // $color-black
    --secondary-text-color: #757575; //$color-gray
    --default-text-weight: unset;
    --default-form-field: rgba(0,0,0,.54); // grayish mat-form-field default
    --detail-label-text-color: #000000; // $color-black
    --text-weight-bold: 600;
    --content-background: #FFFFFF; // $color-white
    --content-background-light: #dfe4ec; // $color-cool-gray;
    --default-menu: var(--content-background);
    --secondary-menu: var(--content-background-light);
    --light-menu: var(--content-background-light);
    --tab-link-dark-theme-text-color: #4f5869; // $color-blue-gray
    --mat-table-background: #FFFFFF; // $color-white
}

[data-theme="dark"] {
    --default-text-color: #f5f5f5; // $color-off-white
    --secondary-text-color: #9ba3ae; // $secondary-text-color
    --default-text-weight: lighter;
    --default-form-field: #f5f5f5; // $color-off-white
    --detail-label-text-color: #FFFFFF; // $color-white
    --text-weight-bold: 500; // slightly south of bold to combat halo effect of white text on dark background
    --content-background: #2e2e2e; // MS Teams - colorNeutralBackground2Selected
    --content-background-light: #383838; // MS Teams - colorNeutralBackground1Selected
    --default-menu: var(--content-background);
    --secondary-menu: var(--content-background-light);
    --light-menu: #3d3d3d; // MS Teams - colorNeutralBackground1Hover
    --tab-link-dark-theme-text-color: #f5f5f5; // $color-off-white
    --mat-table-background: #3d3d3d; // MS Teams Light
}


// Layout
$std-padding: 8px;
$std-padding2x: $std-padding*2;
$std-padding3x: $std-padding*3;
$std-padding-half: $std-padding*0.5;

// Colors
$std-border-color: var(--default-form-field);

$secondary-text-color: #757575;
