@import './app/shared/variables.scss';

html, body {
    background-color: var(--content-background);
    font-family: 'Roboto', "Helvetica Neue", Arial, Helvetica, Verdana, sans-serif !important;
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    font-size: initial;
    font-weight: initial;
    line-height: initial;
    color: var(--default-text-color);
    font-weight: var(--default-text-weight);
}

.mdc-typography, .mdc-list-item__primary-text, .mdc-button__label {
  font-family: 'Roboto', "Helvetica Neue", Arial, Helvetica, Verdana, sans-serif !important;
}

// Utility class that will remove the scroll bar when applied to the body.
// Mostly helpful when display the sidebar to ensure there aren't double
// scroll bars and the sidebar can scroll independently of everything else.
body.overflow-hidden {
  overflow-y: hidden;
}

::placeholder {
  color: var(--default-text-color) !important;
}

.mat-expansion-panel,
.mat-grid-list,
.mat-mdc-table,
.mat-card {
    background-color: var(--mat-table-background);

    .mat-expansion-panel-header-title,
    .mat-expansion-panel-header-description,
    .mat-mdc-cell,
    .mat-mdc-select-value,
    .mat-mdc-select-arrow,
    .mat-grid-tile,
    .sum-metric {
        font-size: initial;
        color: var(--default-text-color);
        font-weight: var(--default-text-weight);
    }

    .mat-mdc-header-cell,
    h5 {
        font-size: initial;
        color: var(--default-text-color);
        font-weight: var(--text-weight-bold);
    }
}


/* expansion form filters */
.filter-form-content {
  .mat-grid-tile {
    margin-top: 10px;
  }

  .mat-mdc-menu-panel,
  .mat-mdc-form-field {
    .mdc-text-field {
      background-color: inherit;
      color: inherit;
    }
  }
}

.mat-mdc-menu-panel,
.mat-mdc-select-panel,
.modal-content,
.mdc-form-field {
    background-color: var(--content-background);

    .mat-mdc-menu-item,
    .mat-mdc-form-field,
    .mat-mdc-option,
    .mat-mdc-select-arrow {
        font-size: inherit;
        color: var(--default-text-color);
        font-weight: var(--default-text-weight);
        margin: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        background-color: inherit;

        &:hover {
            background-color: $color-bright-blue;
        }

        .mdc-list-item__primary-text {
            width: 100%;
            font-weight: var(--default-text-weight);
        }

        /* mat submenu expand arrow */
        .mat-mdc-menu-submenu-icon {
            color: unset;
        }

        .right-icon {
            margin-left: auto;
            padding-left: 10px;
            margin-right: 0;
            color: unset;
            font-size: inherit;
        }
    }
}

.mat-mdc-paginator {
    background: inherit;
    color: inherit;
    font-size: inherit;
    max-height: 65px;

    .mat-select-trigger,
    .mat-mdc-select-value,
    .mat-mdc-select-arrow,
    .mat-mdc-paginator-page-size-select,
    .mat-mdc-icon-button {
        @extend .mat-mdc-table;
        background: inherit;
        color: var(--default-text-color);

        svg {
          fill: var(--default-text-color);
        }
    }
}

.mat-mdc-tab-group {
  .mat-mdc-tab {
    font-size: inherit;
    color: var(--secondary-text-color);
    font-weight: var(--default-text-weight);
    opacity: .65;
    width: 250px;

    .mdc-tab__text-label {
      color: var(--secondary-text-color) !important;
    }

    .mdc-tab-indicator__content--underline {
      border-color: $color-bright-blue;
    }

    &:hover {
      opacity: unset;
    }

    &.mdc-tab--active {
      font-weight: 500;
      opacity: unset;
    }
  }
}

.mat-mdc-tab-group.small-tab-group {
  .mat-mdc-tab {
    min-width: 0;
    width: 30px;
    height: 30px;
    padding: 0;
  }
  img {
    height: 20px;
    width: 20px;
  }
}


// .mat-tab-group {
//   mat-tab-header {
//     margin: 0 10%;
//   }
//   .mat-tab-label {
//     font-size: inherit;
//     color: var(--secondary-text-color);
//     font-weight: var(--default-text-weight);
//     opacity: .65;
//     &:hover {
//       opacity: unset;
//     }
//     &.mat-tab-label-active {
//       font-weight: 500;
//       opacity: unset;
//     }
//   }
//   &.mat-primary .mat-ink-bar {
//     background: $color-bright-blue;
//   }
// }

// .mat-tab-group.small-tab-group {
//   .mat-tab-header {
//     border: none;
//     box-shadow: none;
//     margin: unset;
//   }
//   .mat-tab-label {
//     min-width: 0;
//     width: 30px;
//     height: 30px;
//     padding: 0;
//   }
//   img {
//     height: 20px;
//     width: 20px;
//   }
//   img {
//     height: 20px;
//     width: 20px;
//   }
// }

.mat-raised-button {
    background-color: $color-deep-blue;
    color: $color-white;
}
.mat-raised-button[disabled] {
    background-color: $color-cool-gray !important;
}

.mat-stroked-button {
    color: $color-deep-blue;
}
.mat-stroked-button[disabled] {
    background-color: $color-cool-gray !important;
}

.mat-spinner circle {
    stroke: $color-deep-blue;
}

.mat-mdc-select-value {
  color: var(--default-text-color);
}


.mat-mdc-form-field .mdc-line-ripple {
  background-color: red; /* Static color for the underline */
}

.mat-mdc-form-field .mdc-line-ripple--active {
  background-color: red; /* Color when active */
}

.mat-mdc-form-field .mdc-line-ripple--deactivating {
  background-color: red; /* Color when deactivating */
}




.mat-mdc-select-arrow {
  color: var(--default-text-color);
}

.loading-shade {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba($color-gray, 0.3);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
 }

.mat-option-text {
    // This catches flyouts that aren't rendered under the control they are parented by
    font-size: 16px;
}

.mat-form-field-appearance-legacy .mat-form-field-label,
.mat-datepicker-toggle {
    color: var(--default-form-field);
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: var(--default-form-field);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: $color-deep-blue;
}

.mat-form-field.mat-focused .mat-form-field-label,
.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
    color: $color-deep-blue;
}

.mat-date-range-input,
.mat-date-range-input .mat-input-element,
.mat-date-range-input-separator,
.mat-form-field-appearance-fill .mat-form-field-flex,
.mat-date-range-input .mat-form-field-label,
.mat-form-field-empty.mat-form-field-label,
.mat-form-field-label:not(.mat-form-field-empty),
.mat-form-field .mat-form-field-underline {
  color: var(--default-text-color);
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: unset;
}
.mat-date-range-input .mat-form-field-underline {
  border-color: var(--default-text-color);
}




.material-icons.md-10,
.material-icons-outlined.md-10 {
    font-size: 10px !important;
    height: 10px !important;
    width: 10px !important;
  }

.material-icons.md-16,
.material-icons-outlined.md-16 {
    font-size: 16px !important;
    height: 16px !important;
    width: 16px !important;
  }

.material-icons.md-18,
.material-icons-outlined.md-18 {
    font-size: 18px !important;
    height: 18px !important;
    width: 18px !important;
  }

.material-icons.md-20,
.material-icons-outlined.md-20 {
    font-size: 20px !important;
    height: 20px !important;
    width: 20px !important;
  }

.material-icons.md-22,
.material-icons-outlined.md-22 {
    font-size: 22px !important;
    height: 22px !important;
    width: 22px !important;
}

.material-icons.md-24,
.material-icons-outlined.md-24{
    font-size: 24px !important;
    height: 24px !important;
    width: 24px !important;
}

.material-icons.md-26,
.material-icons-outlined.md-26{
    font-size: 26px !important;
    height: 26px !important;
    width: 26px !important;
}

.material-icons.md-34,
.material-icons-outlined.md-34 {
    font-size: 34px !important;
    height: 34px !important;
    width: 34px !important;
}

.material-icons.md-36,
.material-icons-outlined.md-36 {
    font-size: 36px !important;
    height: 36px !important;
    width: 36px !important;
}

.material-icons.md-40,
.material-icons-outlined.md-40 {
    font-size: 40px !important;
    height: 40px !important;
    width: 40px !important;
}

.material-icons.md-48,
.material-icons-outlined.md-48 {
    font-size: 48px !important;
    height: 48px !important;
    width: 48px !important;
}

sk-click-to-copy,
mat-icon {
    &.success {
        color: $icon-success;
    }

    &.warning {
        color: $icon-warning;
    }

    &.muted {
        color: $icon-muted;
    }

    &.error {
        color: $color-red;
    }

    &.blue {
        color: $color-bright-blue;
    }
}

// sk-order
.component-container {
  width: 100%;
  max-width: none;
  display: flex;
  flex-direction: column;
  background-color: var(--content-background);
}

.clickable {
    font-weight: var(--default-text-weight);
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: $color-bright-blue;
        text-decoration: none;

        * {
            color: $color-bright-blue;
        }
    }
}

.bsp-content-container {
    text-align: left;
    padding: 0px 8%;
}

.table {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--default-text-color);

    .wrapper {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
    }

    .row {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        width: 100%;
        padding-top: 5px;
        padding-bottom: 5px;

        &:nth-child(2n+1) {
            background-color: var(--content-background-light);
        }

        &.header {
            color: var(--default-text-color);
            font-weight: var(--text-weight-bold);
            background-color: var(--content-background);
        }

        &.lazy-wrap {
            flex-wrap: wrap;
        }

        span,.column {
            flex-grow: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-right: 10px;
            padding-left: 10px;
            width: 20px;
            color: var(--default-text-color);

            > .clickable {
                font-weight: var(--default-text-weight);
            }
        }
    }
}

.table-wrapper {
    margin-top: 10px;
    width: 80%;
    align-self: center;
}

.loading-spinner {
    margin: 25px auto 25px auto;
    color: $color-deep-blue;
}

.loading-spinner-modal {
    @extend .loading-spinner;
    display: flex;
    justify-content: center;
}

input.form-control {
    font-size: 18px;
    background-color: var(--content-background);
    border: 1px solid $color-gray;
    border-radius: 0.25rem;
    color: $color-blue-gray;
    padding: 5px 10px;

    &::placeholder {
        color: transparentize($color-blue-gray, 0.3);
    }
}

.subTab-title {
    margin-top: $content-top-margin;
    display: flex;
    justify-content: center;
    font-size: 2em;
}

.break-word {
    word-break: break-all;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.auto-complete-panel {
  max-height: 500px !important;

  .auto-complete-option {
    height: 60px;
    line-height: 30px;
  }

  .auto-complete-option:nth-child(2n+2) {
    background-color: $search-result;

    &:hover {
      background-color: $search-result-hover;
    }
}

  .auto-complete-option-large {
      height: 90px;
      line-height: 30px;
  }
}

.version-indicator {

    position:fixed;
    bottom:0;
    left: 0;
    color: lightgray;

    &:hover {
        cursor: pointer;
    }
}

.element {
    border: #efefef solid 1px;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 8px 0px;

    &:hover {
        cursor: pointer;
    }

    &.edit-mode {
        cursor: default;
    }

    .element-name {
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.ngx-json-viewer {
  overflow: auto !important;
  padding-bottom: 10px !important;

  .segment-key {
    color: #B3679A !important;
  }
  .segment-separator {
    color: var(--default-text-color) !important;
  }

  .segment-value {
    color: #6547FF !important;
  }

  .segment-type-null > .segment-main > .segment-value {
    background-color: transparent !important;
  }
}

// when we update the ngx json viewer to a newer version you can use variables for easier theming
// :root {
//   --ngx-json-string: var(--default-text-color);
//   --ngx-json-key: #3f51b5;
//   --ngx-json-boolean: #4caf50;
//   --ngx-json-number: var(--secondary-text-color);
//   --ngx-json-null: #9e9e9e;
//   --ngx-json-brace: var(--default-text-color);
//   --ngx-json-bracket: var(--default-text-color);
// }

.flex-container {
  display:flex;
  width:100%;


  &.flex-column {
    flex-direction: column;
  }

  &.flex-row {
    flex-direction: row;
  }

  .flex-grow {
    flex-grow: 1;
  }

  &.space-around {
    justify-content: space-around;
  }
}

.side-drawer-container {
  flex-direction: column;
  display: flex;
  height: 100%;

  .side-drawer-header {
    font-size: 24px;
    background-color: $color-deep-blue;
    padding: 35px;

    color: $color-white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .text {
      flex-grow: 1;
    }

  }

  .side-drawer-content-container {
    overflow-y: auto;
    height: auto;
    flex-grow: 1;


    .side-drawer-sub-section-header {
      padding: 8px;
      background-color: $color-bright-blue;
      color: white;
      text-align: center;
      box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
    }


    .side-drawer-content {
      padding: 8px;
    }


  .empty-state {
    place-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 24px;
  }
  }

  .navigatable {
    cursor: pointer;
  }
}

.no-text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


.page-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: initial;
  margin: 0 20%;
}

.mat-grid-left-justify {
  .mat-grid-tile-content { justify-content: flex-start; align-items: flex-start; }
}

/* mat-dialog */
.mat-mdc-dialog-container {
  .mdc-dialog__title,
  .mdc-dialog__content,
  .mdc-dialog__container .mat-mdc-dialog-surface {
    background-color: var(--content-background-light);
    color: inherit !important;
  }
}

// left hand vertical nav bar
.sidenav-container-main {
  .drawer {
    .mat-drawer-inner-container {
      display: flex;
    }

    .nav-container {
      .nav-item {
        .mdc-list-item__content,
        .mat-list-item-content {
          padding: 0;
          display: flex;
          flex: 1;
          margin: 0 15px;
        }
      }

      .nav-submenu {
        .mat-expansion-panel-header {
          padding: 0;

          .mat-content {
            flex-grow: unset;
            flex-basis: unset;

            .mat-expansion-panel-header-title {
              margin-right: unset;
            }

            .mdc-list-item__content,
            .mat-list-item-content {
              padding: unset;
            }
          }

          .mat-expansion-indicator {
            margin-left: auto;
            margin-right: 10px;
            &::after {
              color: var(--default-text-color);
            }
          }
        }

        .mat-mdc-expansion-panel-body,
        .mat-expansion-panel-body {
          padding: 0;

          .mat-mdc-list-base,
          .mat-list-base {
            padding: 0;
          }
        }
      }
    }
  }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--default-text-color);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
.mdc-tooltip {
  font-family: 'Roboto', "Helvetica Neue", Arial, Helvetica, Verdana, sans-serif !important;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  background-color: inherit;
}

.mat-slide-toggle-content {
  font-family: inherit;
  font-weight: inherit;
}

.mat-mdc-slide-toggle .mdc-form-field {
  background-color: unset;
  color: var(--default-text-color);
}

:root {
  --mdc-theme-primary: #{$color-deep-blue};

  .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: #{$color-deep-blue} !important;
  }

  .mat-mdc-snack-bar-container {
    --mdc-snackbar-container-color: #{var(--default-menu)};
    --mat-mdc-snack-bar-button-color: #{$color-deep-blue};
    --mdc-snackbar-supporting-text-color: #{var(--default-text-color)};
  }

  .mat-mdc-fab[disabled] {
    --mat-mdc-fab-color: #{var(--secondary-text-color)};
  }
  .mat-mdc-fab.mat-accent {
    --mdc-fab-container-color: #{$color-deep-blue};
  }
}
